import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function TeamPage() {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      allContentfulPageTeam {
        edges {
          node {
            pageTitle
            heroSectionHeading
            heroSectionImage {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            bodySectionContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageTeam.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Page data={dataNode} />
    </Layout>
  );
}

export default TeamPage;
